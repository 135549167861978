export default {
  404: {
    title: '<span class="accent">404</span> Page not found',
    text: "It seems that the page you were looking for no longer exists or has been moved.<br> Don't worry, we will help you find your way back.",
  },
  500: {
    title: '<span class="accent">500</span> Internal Server Error',
    text: 'Sorry, an internal error has occurred. <br />We are working to fix the issue as soon as possible. <br /> Try going back to the homepage or try again later.',
  },
  suggestions: {
    title: 'Here are some things you can do:',
    backhomeCta: 'Back to Homepage',
    backhomeText: 'start fresh and learn about all the sections of our site.',
    contactsCta: 'Get in touch',
    contactsText: 'if you need assistance, please feel free to contact us.',
  },
}
