export default {
  menu: {
    title: 'Get in touch',
    milano: 'MILANO {separator} VIA BORSI 9',
    spezia: 'LA SPEZIA {separator} VIA SAPRI 68',
    mail: 'Mail',
    careers: 'Join the team',
  },
  newsletter: {
    title: 'Stay tuned',
    emailField: 'Email',
    emailPlaceholder: 'Your mail',
    cta: 'Subscribe',
    gdpr: 'Ai sensi e per gli effetti del Reg. UE 2016/679 - GDPR presto il consenso al trattamento dei miei dati personali come da <a target="_blank" href="/privacy-policy">informativa sulla privacy</a>',
    gdprError: 'Campo obbligatorio',
    successTitle: 'Grazie per esserti iscritto alla nostra newsletter!',
    resetCta: 'Nuova iscrizione',
  },
  bottom: {
    corporate:
      'Sun-TIMES srl - sede legale: Via Giosuè Borsi, 9, 20143 Milano MI {separator} Pra 3443 Rea 111454 {separator} PI 01231370113',
    cookie: 'Cookie',
    privacy: 'Privacy',
    whistleblowing: 'Whistleblowing',
  },
}
