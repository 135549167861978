export default {
  'menu-nav': {
    menu: 'Menu',
    who: 'Who',
    what: 'What',
    why: 'Why',
    getInTouch: 'Get in touch',
    back: 'Back',
  },
}
