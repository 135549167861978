import errors from './errors.js'
import menu from './menu.js'
import footer from './footer.js'
import cta from './cta.js'

import pages from './pages/index.js'

export default {
  errors,
  menu,
  footer,
  cta,
  pages,
}
