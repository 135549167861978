export default {
  404: {
    title: '404 Pagina non trovata',
    text: 'La pagina che cerchi non è disponibile.<br /> Prova a tornare alla homepage o riprova più tardi',
  },
  500: {
    title: '500 Errore interno del server',
    text: 'Spiacenti, si è verificato un errore interno. <br />Stiamo lavorando per risolvere il problema il prima possibile.<br /> Prova a tornare alla homepage o riprova più tardi.',
  },
}
