export default {
  menu: {
    title: 'CONTACT',
    milano: 'MILANO {separator} VIA BORSI 9',
    spezia: 'LA SPEZIA {separator} VIA SAPRI 68',
    mail: 'Mail',
    careers: 'Join the team',
  },
  newsletter: {
    title: 'Stay tuned',
    subtitle:
      'Do not miss out on anything. Subscribe to our newsletter to stay updated on our events and creative news.',
    emailField: 'Email',
    emailPlaceholder: 'Your mail',
    cta: 'Subscribe',
    gdpr: 'Pursuant to and in accordance with EU Reg. 2016/679 - GDPR I give my consent to the processing of my personal data as per the <a target=“_blank” href=“/privacy-policy”>privacy notice</a>',
    gdprError: 'Required field',
    successTitle: 'Thank you for subscribing to our newsletter!',
    resetCta: 'New subscription',
  },
  bottom: {
    corporate:
      'Sun-TIMES srl - registered office: Via Giosuè Borsi, 9, 20143 Milan MI {separator} Pra 3443 Rea 111454 {separator} PI 01231370113',
    cookie: 'Cookie',
    privacy: 'Privacy',
    whistleblowing: 'Whistleblowing',
  },
}
